import React from "react";
import PropTypes from "prop-types";

import ImBackButton from "./ImBackButton";
import MessageBackButton from "./MessageBackButton";
import PostBackButton from "./PostBackButton";
import OpenUrlButton from "./OpenUrlButton";

// "cardAction" could be either, "imBack", "messageBack", or "postBack".
const CardActionButton = ({ cardAction }) => {
  switch (cardAction.type) {
    case "messageBack":
      return <MessageBackButton cardAction={cardAction} />;

    case "postBack":
      return <PostBackButton cardAction={cardAction} />;

    case "openUrl":
      return <OpenUrlButton cardAction={cardAction} />;

    default:
      return <ImBackButton cardAction={cardAction} />;
  }
};

CardActionButton.propTypes = {
  cardAction: PropTypes.shape({
    type: PropTypes.string,
  }).isRequired,
};

export default CardActionButton;
