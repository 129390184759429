import React, { useCallback } from "react";
import { hooks } from "botframework-webchat-component";
import PropTypes from "prop-types";

const { useSendMessage } = hooks;

const ImBackButton = ({ cardAction: { title, value } }) => {
  const sendMessage = useSendMessage();
  const handleClick = useCallback(() => sendMessage(value), [value, sendMessage]);

  return (
    <button
      // ImBack is essentially sending a message
      className="suggested-actions__button"
      onClick={handleClick}
      type="button"
    >
      {title}
    </button>
  );
};

ImBackButton.propTypes = {
  cardAction: PropTypes.exact({
    type: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};

export default ImBackButton;
