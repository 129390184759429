/* eslint-disable arrow-body-style */
import React from "react";
import PropTypes from "prop-types";
import { LiveMessage } from "react-aria-live";

const ListBox = ({ options, defaultOptions, cursor }) => {
  let message = "";

  if (options.length > 0) {
    message = `${options.length + defaultOptions.length} options available.`;
  }

  return (
    <>
      <LiveMessage message={message} aria-live="polite" clearOnUnmount="true" />
      <ul id="suggestions-list" role="listbox" >
        {options.map((value, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Option key={index} value={value} cursor={cursor} />
        ))}
        {defaultOptions.map((value, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Option key={index} value={value} cursor={cursor} />
        ))}
      </ul>
    </>
  );
};

const Option = ({ value, cursor }) => {
  const { text, hightLightTxtStartIndx, highlightTxtLen, key, onClick, onKeyDown } = value;
  const isActive = key === cursor;
  return (
    <li
      data-index={key}
      role="option"
      aria-selected={false}
      key={key}
      id={key}
      className={`${isActive ? "auto-complete-active-suggestion" : "auto-complete-suggestions"}`}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      {hightLightTxtStartIndx && hightLightTxtStartIndx !== -1 ? (
        <>
          {text.slice(0, hightLightTxtStartIndx)}
          <span style={{ fontWeight: "bolder" }}>
            {text.slice(hightLightTxtStartIndx, hightLightTxtStartIndx + highlightTxtLen)}
          </span>
          {text.slice(hightLightTxtStartIndx + highlightTxtLen)}
        </>
      ) : (
        text
      )}
    </li>
  );
};

const ValuePropTypes = PropTypes.exact({
  text: PropTypes.string.isRequired,
  hightLightTxtStartIndx: PropTypes.number,
  highlightTxtLen: PropTypes.number,
  key: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
});

Option.propTypes = {
  value: ValuePropTypes.isRequired,
  cursor: PropTypes.string.isRequired,
};

ListBox.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: ValuePropTypes,
    })
  ).isRequired,
  defaultOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: ValuePropTypes,
    })
  ).isRequired,
  cursor: PropTypes.string.isRequired,
};

export default ListBox;
