import React, { useCallback } from "react";
import { hooks } from "botframework-webchat-component";
import PropTypes from "prop-types";

const { useSendMessageBack } = hooks;

const MessageBackButton = ({ cardAction: { displayText, text, title, value } }) => {
  const sendMessageBack = useSendMessageBack();

  const handleClick = useCallback(
    () => sendMessageBack(value, text, displayText),
    [displayText, sendMessageBack, text, value]
  );

  return (
    <button
      // Web Chat does the heavy lifting for us by exposing a "sendMessageBack" function.
      // We do need to handle "displayText" manually in our renderer though.
      className="suggested-actions__button"
      onClick={handleClick}
      type="button"
    >
      {title}
    </button>
  );
};

MessageBackButton.propTypes = {
  cardAction: PropTypes.exact({
    type: PropTypes.string,
    displayText: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.object,
  }).isRequired,
};

export default MessageBackButton;
