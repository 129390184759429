import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { hooks } from "botframework-webchat-component";

const { useSendPostBack } = hooks;

const PostBackButton = ({ cardAction: { title, value } }) => {
  const sendPostBack = useSendPostBack();
  const handleClick = useCallback(() => sendPostBack(value), [value, sendPostBack]);

  return (
    <button
      // Web Chat does the heavy lifting for us by exposing a "sendPostBack" function.
      className="suggested-actions__button"
      onClick={handleClick}
      type="button"
    >
      {title}
    </button>
  );
};

PostBackButton.propTypes = {
  cardAction: PropTypes.exact({
    type: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};

export default PostBackButton;
