import React from "react";
import { LiveAnnouncer } from "react-aria-live";

import "./App.css";

import lillyLogo from "./assets/LillyLogo.png";
import MenuBasedWebChat from "./web-chat/MenuBasedWebChat";

function App() {
  return (
    <div className="App">
      <header className="App-header" role="banner">
        <a className="skip-link" href="#main">
          Skip to main
        </a>
        <div className="App-row App-align-center">
          <img src={lillyLogo} alt="Lilly" className="App-logo" />
          <p className="App-light-heading">Medical Information</p>
          {/* <p>Click To Chat</p> */}
        </div>
        <div className="App-light-heading App-col App-align-center">
          <span>Trulicity®</span>
          <span>(dulaglutide)</span>
        </div>
      </header>
      <section className="App-body">
        <main id="main" className="App-chatwidget-container">
          <div className="App-chatwidget">
            <LiveAnnouncer>
              <MenuBasedWebChat />
            </LiveAnnouncer>
          </div>
        </main>
      </section>
    </div>
  );
}

export default App;
