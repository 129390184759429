import qs from "qs";
import React, { useMemo, useCallback, useState } from "react";
import { createStore, Components } from "botframework-webchat";

import WebChat from "./WebChat";

import "./MenuBasedWebChat.css";

import lillylogo from "../assets/Lilly_bot.svg";

const MenuBasedWebChat = () => {
  const [token, setToken] = useState();
  const [userID, setUserID] = useState();
  const [suggestions, setSuggestions] = useState({ values: [], stopWords: [], fallBack: "" });
  const [enableAutoCompleteSendBox, setEnableAutoCompleteSendBox] = useState({
    state: false,
  });
  const [disableAutoCompleteSendBox, setDisableAutoCompleteSendBox] = useState({
    state: false,
  });
  const [enableNormalSendBox, setEnableNormalSendBox] = useState({
    state: false,
  });

  // const store = useMemo(() => createStoreWithDevTools({}, ({ dispatch }) => next => action => {
  const store = useMemo(
    () =>
      createStore({}, ({ dispatch }) => (next) => (action) => {
        if (action.type === "DIRECT_LINE/CONNECT_FULFILLED") {
          const queryParams = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
          });
          const info = {
            timeOpened: new Date(),
            timezone: new Date().getTimezoneOffset() / 60,
            pageOn: window.location.pathname,
            referrer: document.referrer,
            browserName: window.navigator.appName,
            browserEngine: window.navigator.product,
            browserVersion1a: window.navigator.appVersion,
            browserVersion1b: navigator.userAgent,
            browserLanguage: navigator.language,
            browserPlatform: navigator.platform,
            sizeScreenW: window.screen.width,
            sizeScreenH: window.screen.height,
            sizeDocW: window.document.width,
            sizeDocH: window.document.height,
            sizeInW: window.innerWidth,
            sizeInH: window.innerHeight,
            sizeAvailW: window.screen.availWidth,
            sizeAvailH: window.screen.availHeight,
            scrColorDepth: window.screen.colorDepth,
            scrPixelDepth: window.screen.pixelDepth,
            language: window.navigator.language,
            origin: queryParams.origin || "Unknown",
          };
          // console.log(info)

          dispatch({
            type: "WEB_CHAT/SEND_EVENT",
            payload: {
              name: "webchat/join",
              value: info,
            },
          });
        } else if (action.type === "DIRECT_LINE/INCOMING_ACTIVITY") {
          if (action.payload.activity.name === "setQuestions") {
            setSuggestions(action.payload.activity.value);
          } else if (action.payload.activity.name === "setSendBoxState") {
            setEnableAutoCompleteSendBox(action.payload.activity.value);
          } else if (action.payload.activity.name === "setAutoCompleteDisableState") {
            setDisableAutoCompleteSendBox(action.payload.activity.value);
          } else if (action.payload.activity.name === "enableNormalSendBox") {
            setEnableNormalSendBox(action.payload.activity.value);
          } else if (action.payload.activity.name === "echoBack") {
            dispatch({
              type: "WEB_CHAT/SEND_POST_BACK",
              payload: { value: action.payload.activity.value },
            });
          } else if (action.payload.activity.name === "redirectToURL") {
            window.location.href = action.payload.activity.value.url;
          }
        }
        return next(action);
      }),
    []
  );

  const { AdaptiveCardContent } = Components;

  const attachmentMiddleware =
    () =>
    (next) =>
    ({ activity, attachment, ...others }) => {
      if (attachment.contentType === "application/vnd.microsoft.card.adaptive") {
        if (attachment.content.actions) {
          const submitAction = attachment.content.actions.find((action) => action.type === "Action.Submit");
          // console.log("submitAction-->", submitAction);
          if (submitAction) {
            const { activities } = store.getState();
            const messageActivities = activities.filter((msgActivity) => msgActivity.type === "message");
            const lastMessage = messageActivities.pop();
            const recentBotMessage = lastMessage === activity;

            // console.log("activity-->", activity);
            // console.log("lastMessage-->", lastMessage);
            // console.log("attachment-->", attachment);
            // console.log("matching", recentBotMessage);

            return (
              <AdaptiveCardContent
                actionPerformedClassName="card__action--performed"
                content={attachment.content}
                disabled={!recentBotMessage}
              />
            );
          }
        }
      }

      return next({ activity, attachment, ...others });
    };

  // const styleSet = useMemo(
  //     () =>
  //         createStyleSet({
  //             backgroundColor: 'transparent'
  //         }),
  //     []
  // );

  // useEffect(()=> {
  //     (async ()=>{ const results = await fetch('http://lly-chatbot.azurewebsites.net/api/recommendations');
  //         const recommendations = await results.json();
  //         console.log(recommendations);
  //         setSuggestions(recommendations);
  // })()
  // },[])

  const styleOptions = {
    hideSendBox: true,
    rootHeight: "90%",
    hideUploadButton: true,
    // Bubble
    bubbleBackground: "#F2F2F2",
    bubbleBorderColor: "#F2f2f2",
    bubbleBorderRadius: "10px 0px 10px 0px",
    bubbleBorderStyle: "None",
    bubbleBorderWidth: 0,
    bubbleFromUserBackground: "#D52B1E",
    bubbleFromUserBorderColor: "#D52B1E",
    bubbleFromUserBorderRadius: "10px 0px 10px 0px",
    bubbleFromUserBorderStyle: "None",
    bubbleFromUserBorderWidth: 0,
    bubbleFromUserNubOffset: 0, // Either a positive/negative number, or "bottom"
    bubbleFromUserNubSize: undefined, // Or a number. 0 means a sharp corner.
    bubbleFromUserTextColor: "White",
    bubbleImageHeight: 400,
    bubbleMaxWidth: 480, // screen width = 600px
    bubbleMinHeight: 40,
    bubbleMinWidth: 400, // min screen width = 300px, Microsoft Edge requires 372px (https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/13621468/)
    // bubbleNubOffset: 0, // Either a positive/negative number, or "bottom"
    // bubbleNubSize: undefined, // Or a number. 0 means a sharp corner.
    bubbleTextColor: "Black",
    // Avatar
    avatarBorderRadius: "50%",
    avatarSize: 40,
    botAvatarBackgroundColor: "#FFFFFF", // defaults to accent color
    botAvatarImage: lillylogo, // Or a string of URL. Can be a data URI or blob.
    botAvatarInitials: "Livy", // Or a string. Empty strings means it has avatar but not initials inside.
    // userAvatarBackgroundColor: undefined, // defaults to accent color
    // userAvatarImage: lillylogo, // Or a string of URL. Can be a data URI or blob.
    // userAvatarInitials: "test", // Or a string. Empty strings means it has avatar but not initials inside.
    // showAvatarInGroup: 'status', // Or 'sender' or true (on every activity).
    // transcriptActivityVisualKeyboardIndicatorColor: DEFAULT_SUBTLE,
    transcriptActivityVisualKeyboardIndicatorStyle: "dashed",
    transcriptActivityVisualKeyboardIndicatorWidth: 1,
    transcriptVisualKeyboardIndicatorColor: undefined,
    transcriptVisualKeyboardIndicatorStyle: undefined,
    transcriptVisualKeyboardIndicatorWidth: undefined,
  };

  const handleFetchToken = useCallback(async () => {
    if (!token) {

      const url = process.env.REACT_APP_TOKEN_API_URL;

      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "content-type": "application/json",
          // "x-api-key": "Meerym8cU169Yyj96SB8h2kXVs5ciSNS07LnA47f",
        },
      });

      const { token: authToken, userId } = await res.json();

      setToken(authToken);
      setUserID(userId);
    }
  }, [setToken, token]);

  return (
    <WebChat
      className="react-web-chat"
      onFetchToken={handleFetchToken}
      attachmentMiddleware={attachmentMiddleware}
      suggestions={suggestions}
      store={store}
      // styleSet={styleSet}
      styleOptions={styleOptions}
      token={token}
      userID={userID}
      disableAutoCompleteSendBox={disableAutoCompleteSendBox}
      enableAutoCompleteSendBox={enableAutoCompleteSendBox}
      enableNormalSendBox={enableNormalSendBox}
    />
  );
};

export default MenuBasedWebChat;
