import { hooks } from "botframework-webchat-component";
import BasicFilm from "react-film";
import React from "react";

import CardActionButton from "./CardActionButton";
import "./SuggestedActions.css";

const { useSuggestedActions } = hooks;

// Web Chat cleaned up the suggestedActions for us.
// If the last activity is from the bot and contains "suggestedActions",
// Web Chat will send it to us thru "suggestedActions".
const SuggestedActions = () => {
  const suggestedActions = useSuggestedActions();
  // console.log(suggestedActions);

  return (
    !!suggestedActions[0].length && (
      <div className="suggested-actions__carousel">
        <BasicFilm autoCenter={false} autoHide flipperBlurFocusOnClick showDots={false} showScrollBar={false}>
          {suggestedActions[0].map((cardAction, index) => (
            <CardActionButton key={index.toString()} cardAction={cardAction} />
          ))}
        </BasicFilm>
      </div>
    )
  );
};

export default SuggestedActions;
