import React, { useEffect, useMemo } from "react";
import { createDirectLine, Components } from "botframework-webchat";
import PropTypes from "prop-types";

import SuggestedActions from "./SuggestedActions";
import AutoComplete from "./AutoComplete";

import lillylogo from "../assets/LillyLogo_Red.jpeg";
import "./WebChat.css";

const WebChat = ({
  className,
  onFetchToken,
  suggestions,
  styleOptions,
  store,
  token,
  userID,
  attachmentMiddleware,
  enableAutoCompleteSendBox,
  disableAutoCompleteSendBox,
  enableNormalSendBox,
}) => {
  const directLine = useMemo(() => createDirectLine({ token }), [token]);
  const { Composer, BasicWebChat } = Components;

  useEffect(() => {
    onFetchToken();
  }, [onFetchToken]);

  return token && userID ? (
    <>
      <Composer
        directLine={directLine}
        userID={userID}
        store={store}
        styleOptions={styleOptions}
        attachmentMiddleware={attachmentMiddleware}
      >
        <BasicWebChat className={`${className || ""} web-chat`} />
        <SuggestedActions role="region" />
        <AutoComplete
          role="region"
          aria-label="questions search box"
          suggestions={suggestions}
          enableAutoCompleteSendBox={enableAutoCompleteSendBox}
          enableNormalSendBox={enableNormalSendBox}
          disableAutoCompleteSendBox={disableAutoCompleteSendBox}
        />
      </Composer>
    </>
  ) : (
    <div className={`${className || ""} connect-spinner`}>
      <div className="content">
        <div className="icon">
          {/* <span className="ms-Icon ms-Icon--Robot" /> */}
          <img src={lillylogo} alt="Lilly" className="App-logo" />
        </div>
        <p>Please wait while we are connecting...</p>
      </div>
    </div>
  );
};

WebChat.defaultProps = {
  className: "",
  styleOptions: {},
};

WebChat.defaultProps = {
  token: "",
  userID: "",
};

WebChat.propTypes = {
  className: PropTypes.string,
  onFetchToken: PropTypes.func.isRequired,
  suggestions: PropTypes.exact({
    values: PropTypes.array.isRequired,
    stopWords: PropTypes.array.isRequired,
    fallBack: PropTypes.string.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  styleOptions: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  store: PropTypes.object.isRequired,
  token: PropTypes.string,
  userID: PropTypes.string,
  attachmentMiddleware: PropTypes.func.isRequired,
  enableAutoCompleteSendBox: PropTypes.exact({
    state: PropTypes.bool.isRequired,
  }).isRequired,
  disableAutoCompleteSendBox: PropTypes.shape({
    state: PropTypes.bool.isRequired,
    text: PropTypes.string,
  }).isRequired,
  enableNormalSendBox: PropTypes.shape({
    state: PropTypes.bool.isRequired,
    value: PropTypes.shape({
      action: PropTypes.string,
    }),
    button: PropTypes.shape({
      name: PropTypes.string.isRequired,
      payload: PropTypes.shape({}).isRequired,
    }),
  }).isRequired,
};

export default WebChat;
